import styled from "styled-components";

const FormContainer = styled.div`
  display: grid;
  width: 100%;
  grid-column-gap: 40px;
  grid-row-gap: 25px;
  justify-content: center;
  @media (max-width: 600px) {
    grid-template-columns: repeat(auto-fill, 300px);
    grid-column-gap: 0px;
    grid-row-gap: 20px;
  }
`;

export default FormContainer;
