import React, { useState, useEffect } from "react";
import {
  ButtonsContainer,
  FormContainer,
  Header,
  InputGroup,
  SelectInputGroup,
  Title,
  Button,
} from "../utilities";
import styles from "../assets/ResultComponent.module.css";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Error, FormGroup } from "../utilities/InputGroup";
import Loading from "./Loading";

export default function FormComponent({ setStep, setData }) {
  const schema = yup.object({
    type: yup.string().required("Type is Required"),
    // destination: yup.string().required("Destination is Required"),
    weight: yup
      .string()
      .required("Weight is Required")
      .matches(/\d+/, "Must Be Only Digits"),
  });
  const [destinations, setDestinations] = useState();
  const methods = useForm({
    mode: "onTouched",
    resolver: yupResolver(schema),
    
    defaultValues: {
      type: "1",
      destinationID:"",
      weight: "",
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    control,
    setValue
  } = methods;
  useEffect(()=>{
    if(destinations)
    setValue("destinationID" , destinations[0].DestinationID)
  },[destinations])
  const [disabled, setDisabled] = useState(false);
  
  const saveRouting = async data => {
    console.log(data);
    fetch("https://vsoftrunner.com-eg.net/RoutingCost/api/Free/V1/GetCost", {
      method: "post",
      body: JSON.stringify(data),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => res.json())
      .then(data => setData(data))
      .then(() => setStep(1));
  };
  
  const getOptObjDestination = option => {
    if (!option.DestinationID)
      option = destinations.find(op => op.DestinationID === option);
    return option;
  };
  useEffect(() => {
    fetch(
      "https://vsoftrunner.com-eg.net/RoutingCost/api/Free/V1/GetDestinations"
    )
      .then(res => res.json())
      .then(res => {
        setDestinations(res);
      });
  }, []);
  if (!destinations) return <Loading />;
  return (
    <>
      <div className={styles.resultHeader}>
        <div className=""></div>
        <h1 className="">Routing Cost</h1>
        <div className=""></div>
      </div>
      <div
        style={{ minHeight: "600px", display: "grid", placeItems: "center" }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(saveRouting)}>
            <Box sx={{ marginBottom: "50px" }}>
              <FormContainer>
                <SelectInputGroup
                  label="Type"
                  name="type"
                  items={[
                    { key: "local", value: "1" },
                    { key: "transit", value: "2" },
                  ]}
                  itemName="key"
                  itemValue="value"
                  placeholder="Type"
                />
                <FormGroup label={"DESTINATION"} error={undefined}>
                  <Controller
                    name="destinationID"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <Autocomplete
                          options={destinations}
                          value={value}
                          isOptionEqualToValue={(option, value) =>
                            option.DestinationID === value.DestinationID
                          }
                          getOptionLabel={option => {
                            return option
                              ? getOptObjDestination(option).DestinationName
                              : "Destination";
                          }}
                          onChange={(e, newValue) => {
                            if (newValue) {
                              onChange(newValue.DestinationID);
                            }
                          }}
                          renderInput={params => (
                            <TextField
                              {...params}
                              sx={{
                                "& .MuiOutlinedInput-root": {
                                  borderRadius: "5px",
                                  boxShadow: "none",
                                },
                                border: "none !importants ",
                                width: "300px",
                              }}
                            />
                          )}
                        />
                      );
                    }}
                  />
                </FormGroup>
                {errors.destination ? <Error>{errors.destination.message}</Error> : null}

                <InputGroup
                  label="Weight"
                  input={{
                    placeholder: "Weight",
                  }}
                  name="weight"
                  error={errors.weight}
                />
              </FormContainer>
            </Box>
            <ButtonsContainer>
              <Button disabled={disabled} style={{ background: "#3ED467" }}>
                Submit
              </Button>
            </ButtonsContainer>
          </form>
        </FormProvider>
      </div>
    </>
  );
}
