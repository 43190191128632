import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { tableCellClasses } from "@mui/material/TableCell";
import styles from "../assets/ResultComponent.module.css";
import styled from "styled-components";
import UnexpectedError from "./UnexpectedError";
import NotFound from "./NotFound";
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "black",
    color: "white",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));
export default function ResultComponent({ setStep, data }) {
  const { suggestedAgent, allAgents } = data;
  const { DestinationName, AgentName, Weight, Cost, Type } = suggestedAgent;
  if(!data) return <UnexpectedError/>
  if(!data.suggestedAgent) return <NotFound/>
  return (
    <div className={styles.resultContainer}>
      <header className={styles.resultHeader}>
        <button className={styles.backBtn} onClick={() => setStep(0)}>
          {"<"}
        </button>
        <h1 className="">Routing Cost</h1>
        <div className=""></div>
      </header>
      <section className={styles.resultEntryContainer}>
        <div className={styles.resultEntry}>
          <div className={styles.entryLabel}>Agent:</div>
          <div className={styles.entryResult}>{AgentName}</div>
        </div>
        <div className={styles.resultEntry}>
          <div className={styles.entryLabel}>Cost:</div>
          <div className={styles.entryResult}>{Cost}</div>
        </div>
        <div className={styles.resultEntry}>
          <div className={styles.entryLabel}>Type:</div>
          <div className={styles.entryResult}>{Type}</div>
        </div>
        <div className={styles.resultEntry}>
          <div className={styles.entryLabel}>Destination:</div>
          <div className={styles.entryResult}>{DestinationName}</div>
        </div>
        <div className={styles.resultEntry}>
          <div className={styles.entryLabel}>Weight:</div>
          <div className={styles.entryResult}>{Weight}</div>
        </div>
      </section>
      <section>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <StyledTableCell align="left" sx={{minWidth:"150px"}}>Agent</StyledTableCell>
                <StyledTableCell align="left" sx={{minWidth:"150px"}}>Cost</StyledTableCell>
                <StyledTableCell align="left" sx={{minWidth:"150px"}}>Type1</StyledTableCell>
                <StyledTableCell align="left" sx={{minWidth:"150px"}}>Type2</StyledTableCell>
                <StyledTableCell align="left" sx={{minWidth:"150px"}}>Zone</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {allAgents.map(agent => (
                <TableRow
                  key={agent.Serial}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align="left" sx={{minWidth:"150px"}}>{agent.AgentName}</TableCell>
                  <TableCell align="left" sx={{minWidth:"150px"}}>{agent.Cost}</TableCell>
                  <TableCell align="left" sx={{minWidth:"150px"}}>{agent.Type1}</TableCell>
                  <TableCell align="left" sx={{minWidth:"150px"}}>{agent.Type2}</TableCell>
                  <TableCell align="left" sx={{minWidth:"150px"}}>{agent.Zone}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </section>
    </div>
  );
}
