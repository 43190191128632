import "./App.css";
import { useState } from "react";
import ResultComponent from "./components/Result";
import FormComponent from "./components/Form";
import styles from "./assets/ResultComponent.module.css";


function App() {
  const [data, setData] = useState();
  const [step, setStep] = useState(0);

  if (step === 0) {
    // return
    return <FormComponent setStep={setStep} setData={setData} />;
  } else {
    return <ResultComponent setStep={setStep} data={data} />;
  }
}

export default App;
